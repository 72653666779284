import { type FormEvent, useContext, useState } from "react";
import { FlowId, ViewId } from "@msidentity/SISU/constants/routing-constants";
import globalConfig from "@msidentity/SISU/global-config";
import { GlobalContext } from "@msidentity/SISU/global-context";
import { useTelemetry } from "@msidentity/SISU/telemetry-helpers/use-telemetry";
import { UserActionName } from "@msidentity/SISU/telemetry-helpers/user-action-name";
import { type ICountryInfo } from "@msidentity/SISU/utilities/country-helper";
import { isValidPhoneFormat, isValidSignInName } from "../../../model/account-name";
import { getFullyQualifiedPhoneNumber } from "../../../model/phone-number";
import { configWrapper } from "../../../reset-password-config";
import type { IRecoverAccountStrings } from "../recover-account-interfaces";

export const useRecoverAccount = (recoverAccountStrings: IRecoverAccountStrings) => {
  const {
    globalState: {
      debugInfo: { errorCode },
    },
  } = useContext(GlobalContext);

  const { activeFlavor, cancelUrl, signInName, telemetry } = globalConfig.instance;
  const { isSigninNamePhone } = configWrapper.instance;
  const [signInUserName, setSignInUserName] = useState(signInName.safeHtmlEscapedString ?? "");
  const [error, setError] = useState(errorCode ? recoverAccountStrings.serverErrorMessage : "");
  const [isPhoneNumberMode, togglePhoneMode] = useState(isSigninNamePhone);
  const [hasFocus, setHasFocus] = useState(true);

  const { logUserAction } = useTelemetry(telemetry, {
    activeView: ViewId.ResetPasswordSignInName,
    activeFlow: FlowId.ResetPasswordSignInName,
    activeFlavor,
  });

  const onValueChange = (value: string) => {
    setSignInUserName(value);
  };

  const onPhoneNumberChange = (country: ICountryInfo, phone: string) => {
    setSignInUserName(getFullyQualifiedPhoneNumber(phone, country));
  };

  const onUseEmailClickHandler = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    setSignInUserName("");
    setError("");
    togglePhoneMode(false);
  };

  const blurHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    setHasFocus(false);

    if (isValidPhoneFormat(signInUserName)) {
      setError("");
      togglePhoneMode(true);
    }
  };

  const focusHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    setHasFocus(true);
  };

  const onCancelClickHandler = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    if (cancelUrl) {
      logUserAction({ actionName: UserActionName.ResetPasswordSignInNameCancelClicked });
      window.location.assign(cancelUrl);
    }
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    logUserAction({ actionName: UserActionName.ResetPasswordSignInNameSubmitClicked });

    let isReadyForSubmit = false;

    if (isPhoneNumberMode && !isValidPhoneFormat(signInUserName)) {
      setError(recoverAccountStrings.validationErrorMessagePhone);
      isReadyForSubmit = false;
    } else if (isPhoneNumberMode && isValidPhoneFormat(signInUserName)) {
      setError("");
      isReadyForSubmit = true;
    } else if (!isValidSignInName(signInUserName)) {
      setError(recoverAccountStrings.validationErrorMessage);
    } else if (isValidPhoneFormat(signInUserName)) {
      setError("");
      togglePhoneMode(true);
    } else {
      setError("");
      isReadyForSubmit = true;
    }

    if (!isReadyForSubmit) {
      event.preventDefault();
    }
  };

  return {
    value: signInUserName,
    isPhoneNumberMode,
    error,
    togglePhoneMode,
    onSubmit,
    onValueChange,
    errorCode,
    onPhoneNumberChange,
    onUseEmailClickHandler,
    blurHandler,
    onCancelClickHandler,
    hasFocus,
    focusHandler,
  };
};
