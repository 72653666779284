import React from "react";
import { InputFabric as Input } from "@msidentity/SISU/components/inputs/input/fabric/input-fabric";
import { LinkButton } from "@msidentity/SISU/components/link-button";
import { PhoneNumberFabricDeprecated } from "@msidentity/SISU/components/phone/fabric/phone-number-fabric-deprecated";
import { ViewContainerFabric } from "@msidentity/SISU/components/view-container/fabric/view-container-fabric";
import { FlowId, ViewId } from "@msidentity/sisu/constants/routing-constants";
import globalConfig from "@msidentity/sisu/global-config";
import { useActivateView } from "@msidentity/sisu/hooks/use-activate-view";
import { useDocumentTitle } from "@msidentity/SISU/hooks/use-document-title";
import useCommonStylesFabric, {
  useStaticCommonStylesFabric,
} from "@msidentity/SISU/styles/fabric/fabric.styles";
import { getDefaultCountry, parseCountryList } from "@msidentity/sisu/utilities/country-helper";
import {
  configWrapper as ResetPasswordConfig,
  countryPrefixList,
} from "../../../reset-password-config";
import { useRecoverAccount } from "../hooks/use-recover-account";
import { recoverAccountStringsFabric as strings } from "./recover-account-strings-fabric";

/**
 * RecoverAccountView
 * @returns RecoverAccountView
 */
export const RecoverAccountViewFabric: React.FC = function RecoverAccountViewFabric() {
  useActivateView(ViewId.ResetPasswordSignInName, FlowId.ResetPasswordSignInName, {
    showIdentityBanner: false,
  });

  useDocumentTitle(strings.title);
  useStaticCommonStylesFabric();

  const { resetPwdAction, phoneCountry } = ResetPasswordConfig.instance;
  const { returnUrl, postUrl, canaryTokenValue, canaryTokenName } = globalConfig.instance;

  const {
    value,
    isPhoneNumberMode,
    error,
    onSubmit,
    onValueChange,
    errorCode,
    onPhoneNumberChange,
    onUseEmailClickHandler,
    onCancelClickHandler,
    blurHandler,
    hasFocus,
    focusHandler,
  } = useRecoverAccount(strings);

  const fabricStyles = useCommonStylesFabric();
  const countryData = parseCountryList(countryPrefixList, true);
  const defaultCountryData = getDefaultCountry(countryData, phoneCountry);
  const description = isPhoneNumberMode ? strings.phoneNumberMsg : strings.description;

  return (
    <form
      id="resetPwdForm"
      name="resetPwdForm"
      method="post"
      noValidate
      action={postUrl}
      data-testid="resetPwdForm"
      onSubmit={onSubmit}
    >
      <ViewContainerFabric
        title={{
          title: strings.title,
          titleId: "iResetPwdHipTitle",
        }}
        description={{
          description,
          descriptionId: "iExplanationText",
        }}
        primaryButton={{
          ariaDescribedBy: "iExplanationText",
          ariaLabelledBy: "iResetPwdHipTitle",
          buttonId: "resetPwdHipAction",
          type: "submit",
          label: strings.submitBtn,
        }}
        secondaryButton={{
          ariaDescribedBy: "iExplanationText",
          ariaLabelledBy: "iResetPwdHipTitle",
          buttonId: "resetPwdHipCancel",
          label: strings.cancelBtn,
          onClick: onCancelClickHandler,
        }}
      >
        <input type="hidden" id="iAction" name="iAction" value={resetPwdAction} />
        <input type="hidden" id="iRU" name="iRU" value={returnUrl} />
        <input
          type="hidden"
          id="isSigninNamePhone"
          name="isSigninNamePhone"
          value={isPhoneNumberMode ? "true" : "false"}
        />
        <input type="hidden" id="canary" name={canaryTokenName} value={canaryTokenValue} />
        {errorCode && <input type="hidden" id="iErrorCode" name="iErrorCode" value={errorCode} />}

        {isPhoneNumberMode ? (
          <>
            {/* eslint-disable-next-line deprecation/deprecation */}
            <PhoneNumberFabricDeprecated
              errorMessage={error}
              phoneNumberChangeHandler={onPhoneNumberChange}
              dropdownProps={{
                useInlinePhoneNumber: true,
                defaultCountryData,
                countryData,
              }}
              inputProps={{
                value,
                showErrorInline: true,
                externalError: error,
                id: "iSigninName",
                name: "iSigninName",
                placeholder: strings.placeHolderPhoneNumber,
                type: "tel",
                hasFocus,
                onFocus: focusHandler,
                onBlur: blurHandler,
                hasInitialFocus: true,
              }}
            />
            <div className={fabricStyles.row}>
              <div className={fabricStyles.formGroup}>
                <LinkButton
                  linkId="iUsePhoneOrEmailLink"
                  text={strings.useEmailOrSkypeLinkText}
                  onClick={onUseEmailClickHandler}
                />
              </div>
            </div>
          </>
        ) : (
          <Input
            id="iSigninName"
            name="iSigninName"
            type="email"
            aria-labelledby="iResetPwdHipTitle"
            aria-describedby="iExplanationText"
            placeholder={strings.textInputPlaceHolder}
            errorMessage={error}
            value={value}
            onBlur={blurHandler}
            onFocus={focusHandler}
            onChange={(e) => onValueChange(e.target.value)}
            hasInitialFocus
            hasFocus={hasFocus}
          />
        )}
      </ViewContainerFabric>
    </form>
  );
};
