import {
  AltEmailRegEx,
  PhoneNumberFormat,
  SkypeRegex,
} from "@msidentity/SISU/components/inputs/input-regex-constants";

/**
 * Checks if the signin name is a valid email format
 * @param signinName the signin name to check
 * @returns if the signin name is a valid email format
 */
export const isValidEmailFormat = (signinName: string) => !!signinName.match(AltEmailRegEx);

/**
 * Checks if the signin name is a valid Skype name
 * @param signinName the signin name to check
 * @returns if the signin name is a valid Skype name
 */
export const isValidSkypeFormat = (signinName: string) => !!signinName.match(SkypeRegex);

/**
 * Checks if the signin name is a valid phone number format
 * @param signinName the signin name to check
 * @returns if the signin name is a valid phone number format
 */
export const isValidPhoneFormat = (signinName: string) => !!signinName.match(PhoneNumberFormat);

/**
 * Checks if the signin name is a valid email, Skype name, or phone number format
 * @param signinName the signin name to check
 * @returns if the signin name is a valid email, Skype name, or phone number format
 */
export const isValidSignInName = (signinName: string): boolean =>
  isValidEmailFormat(signinName) ||
  isValidSkypeFormat(signinName) ||
  isValidPhoneFormat(signinName);
