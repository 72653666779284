import React from "react";
import { Route } from "react-router-dom";
import RouteAnimation from "@msidentity/SISU/components/route-animation/fabric/route-animation-fabric";
import { RecoverAccountViewFabric } from "../views/recover-account/fabric/recover-account-view-fabric";

/**
 * Component that renders the first sign in view of the reset password flow
 * @returns ResetPwdSignInNameFlow component
 */
export const ResetPwdSignInNameFlowFabric: React.FC = function ResetPwdSignInNameFlowFabric() {
  return (
    <RouteAnimation>
      <Route path="*" element={<RecoverAccountViewFabric />} />
    </RouteAnimation>
  );
};
