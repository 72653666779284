/* eslint-disable deprecation/deprecation */

import React, { useEffect, useState } from "react";
import { mergeClasses } from "@griffel/react";
import StylesConfig from "../../../config/styles-config";
import ErrorContainerFabric from "../../error-container/fabric/error-container-fabric";
import { type PhoneNumberPropsDeprecated } from "../../inputs/phone-number/phone-number-types";
import { TextInputFabricDeprecated } from "../../inputs/text-input/fabric/text-input-fabric-deprecated";
import { PhoneCountryDropdownFabricDeprecated } from "./phone-country-dropdown-fabric-deprecated";

/**
 * @deprecated
 * PhoneNumber component for Fabric
 * @param props The properties for this component.
 * @param props.dropdownProps The props to pass to the phone country dropdown component.
 * @param props.inputProps The props to pass to the text input component.
 * @param props.errorMessage The message to display when there is a validation or external error.
 * @param props.phoneNumberChangeHandler Callback for when the country dropdown or phone number input is updated.
 * @returns A shared component that includes the phone country dropdown and phone number input field
 */
export const PhoneNumberFabricDeprecated: React.FC<PhoneNumberPropsDeprecated> =
  function PhoneNumberFabricDeprecated(props) {
    const {
      dropdownProps,
      inputProps,
      phoneNumberChangeHandler = () => {},
      errorMessage = "",
      appendedLabel = "",
    } = props;

    const { id, value = "", showErrorInline = false } = inputProps;
    const { defaultCountryData, useInlinePhoneNumber } = dropdownProps;

    const { useCommonStyles, usePhoneNumberStyles } = StylesConfig.instance;
    const commonStyles = useCommonStyles();
    const phoneNumberStyles = usePhoneNumberStyles();

    const [country, setCountry] = useState(defaultCountryData);
    const [phoneNumberInput, setPhoneNumberInput] = useState(value);

    useEffect(() => {
      phoneNumberChangeHandler(country, phoneNumberInput);
    }, [country, phoneNumberInput, phoneNumberChangeHandler]);

    return (
      <div className={commonStyles.row}>
        {showErrorInline && errorMessage && (
          <ErrorContainerFabric
            id={`${id}Error`}
            className={mergeClasses(phoneNumberStyles.errorContainer, commonStyles.alertError)}
          >
            {errorMessage}
          </ErrorContainerFabric>
        )}
        <div className={phoneNumberStyles.phoneNumberContainerDeprecated}>
          <PhoneCountryDropdownFabricDeprecated
            {...dropdownProps}
            hasError={showErrorInline && !!errorMessage}
            onChange={(countryValue) => setCountry(countryValue)}
          />
          <div
            className={
              useInlinePhoneNumber
                ? phoneNumberStyles.inlineInput
                : phoneNumberStyles.nonInlineInput
            }
          >
            <TextInputFabricDeprecated
              {...inputProps}
              value={phoneNumberInput}
              externalError={errorMessage}
              onChange={(e) => setPhoneNumberInput(e.target.value)}
              useExternalErrorContainer
            />
          </div>
          {appendedLabel && <div>{appendedLabel}</div>}
        </div>
      </div>
    );
  };
