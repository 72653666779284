import { extractNLastPhoneDigits } from "@msidentity/SISU/model/alias";
import { type ICountryInfo } from "@msidentity/SISU/utilities/country-helper";

/**
 *  Format phone number
 * @param nationalNumber National numebr
 * @param country Country data
 * @returns String representation of the supplied phone number
 */
export const getFullyQualifiedPhoneNumber = (
  nationalNumber: string,
  country?: ICountryInfo | null,
): string => {
  const trimmedNumber = nationalNumber.trim();
  let countryPrefix = "";
  if (trimmedNumber.charAt(0) === "+") {
    countryPrefix = "+";
  } else if (country) {
    countryPrefix = `+${country.code}`;
  }

  return `${countryPrefix}${trimmedNumber.replace("+", "")}`;
};

/**
 * Obfuscates the phone number by replacing all but the last two digits with asterisks
 * @param phoneNumber the phone number to obfuscate
 * @returns the obfuscated phone number
 */
export const obfuscatePhoneNumber = (phoneNumber: string) => {
  if (phoneNumber.length <= 2) {
    return phoneNumber;
  }

  let obfuscatedPhoneNumber = "";

  for (let i = 0; i < phoneNumber.length - 2; i += 1) {
    obfuscatedPhoneNumber += "*";
  }

  obfuscatedPhoneNumber += phoneNumber.substring(phoneNumber.length - 2, phoneNumber.length);
  return obfuscatedPhoneNumber;
};

/**
 * Validates that the last two digits of the input match the last two digits of the phone number
 * @param input the input to validate
 * @param phoneNumber the phone number to validate against
 * @returns if the last two digits of the input match the last two digits of the phone number
 */
export const validateLastPhoneNumberDigits = (input: string, phoneNumber: string) =>
  extractNLastPhoneDigits(input, 2) === extractNLastPhoneDigits(phoneNumber, 2);
